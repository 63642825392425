// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-duo-livre-js": () => import("./../../../src/pages/AboutDuoLivre.js" /* webpackChunkName: "component---src-pages-about-duo-livre-js" */),
  "component---src-pages-about-gabo-js": () => import("./../../../src/pages/AboutGabo.js" /* webpackChunkName: "component---src-pages-about-gabo-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/AboutMe.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

